import React from "react";
import Layout from "../components/Layout/Layout";
import BasketContainer from "../modules/BasketContainer/BasketContainer";
import Head from "../components/Head/Head";

const Basket = () => {
    return (
        <Layout>
            <Head
        description="Oakstore shoping cart 📩 "
        title="Shopping Cart"
        keywords="software, oakfusion store, oakstore"
        externalScript="https://www.google.com/recaptcha/api.js"
        metaImage=""
      />
            <BasketContainer isOnHover={false} />
        </Layout>
    );
};

export default Basket;
